

































































































































































































































































































import Component from 'vue-class-component';
import PlayerV3Base from '@/components/players/js/player-v3-base';

@Component
export default class PlayerV3Linear extends PlayerV3Base {
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }

  handleResize(): void {
    const tracklist = document.querySelector(
      '.tracklist-wrapper'
    ) as HTMLElement;

    // if crossing from mobile to desktop
    if (window.innerHeight >= 600 && this.bodyHeight < 600) {
      tracklist.style.display = 'block';
      this.toggleMainPlay('off');
    }
    // if crossing from desktop to mobile
    else if (window.innerHeight < 600 && this.bodyHeight >= 600) {
      tracklist.style.display = 'none';
      this.toggleMainPlay('on');
    }

    this.bodyHeight = document.body.clientHeight;
  }
}
